/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./CommentTermin.vue?vue&type=template&id=2c612eec&scoped=true&lang=pug&"
import script from "./CommentTermin.vue?vue&type=script&lang=ts&"
export * from "./CommentTermin.vue?vue&type=script&lang=ts&"
import style0 from "./CommentTermin.vue?vue&type=style&index=0&id=2c612eec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c612eec",
  null
  
)

export default component.exports