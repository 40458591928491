"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAgGridReactiveHideCondition = void 0;
const vue_1 = require("vue");
function useAgGridReactiveHideCondition(columnDef, gridColumnApi) {
    let matchColumnHideState = () => {
        var _a, _b;
        let showColumns = columnDef.filter(def => def.reactiveHideCondition === false);
        let hideColumns = columnDef.filter(def => def.reactiveHideCondition === true);
        // console.log(
        //   'show',
        //   showColumns.map(def => def.field),
        //   'hide',
        //   hideColumns.map(def => def.field),
        // );
        (_a = gridColumnApi.value) === null || _a === void 0 ? void 0 : _a.setColumnsVisible(showColumns.map(def => def.field), true);
        (_b = gridColumnApi.value) === null || _b === void 0 ? void 0 : _b.setColumnsVisible(hideColumns.map(def => def.field), false);
    };
    (0, vue_1.watch)(columnDef, matchColumnHideState);
    return { matchColumnHideState };
}
exports.useAgGridReactiveHideCondition = useAgGridReactiveHideCondition;
